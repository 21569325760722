<template>
    <v-container>

        <v-expansion-panels
            v-model="panel"
            multiple
            >
            <!-- project -->
            <v-expansion-panel class="mb-6">
                <v-expansion-panel-header>
                    <v-list-item>
                        <v-list-item-icon class="mr-2">
                            <v-icon>mdi-folder</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5">{{ $t('project_data')}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-spacer></v-spacer>

                    <div class="d-flex justify-end">
                        <!-- REPORT BUTTON -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon style="float:right;"
                                    v-on="{ ...tooltip }"
                                    :to="`/project/${inputData.id}/report`">
                                    <v-icon>mdi-clipboard-text</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('report') }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon style="float:right;"
                                    @click.stop="editProject()"
                                    v-on="{ ...tooltip }">
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('edit') }}</span>
                        </v-tooltip>
                    </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-row>
                        <v-col :cols="12/projectRows.length"
                            v-for="(colValue, cidx) in projectRows"
                            :key="cidx">
                            <div v-for="(row, idx) in colValue"
                                :key="idx">
                                <div v-if="row.type === 'map'">{{  $t('Map') }}
                                    <div style="height:220px;width:250px;border:2px solid rgba(0,0,0,.5)">
                                        <v-map style="z-index:2;" ref="mappy" :zoom=13 :center="projectLocation">
                                            <!-- default tile provider https://{s}.tile.osm.org/{z}/{x}/{y}.png -->
                                            <v-tilelayer url="https://tile.openstreetmap.de/{z}/{x}/{y}.png"></v-tilelayer>
                                        </v-map>
                                    </div>
                                </div>
                                <v-list-item-title v-else :class="['subtitle-1', row.name === null ? 'mb-3' : 'mb-1']">
                                    <v-row>
                                        <v-col cols="4" align-self-center>
                                            <div>
                                                {{ row.name }}
                                            </div>
                                        </v-col>

                                        <v-col cols="8"  align-self-center>
                                            <div v-for="val, vidx in row.value.slice(0, 2)"
                                                :key="`row-val-${vidx}`">

                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{}">
                                                        <div v-if="row.type === 'text'">
                                                            <v-textarea solo flat readonly :value="val.name">
                                                            </v-textarea>
                                                        </div>
                                                        <div v-if="row.type === 'icon'">
                                                            <v-icon x-large>{{ val.name }}</v-icon>
                                                        </div>
                                                        <v-list-item-title v-else class="text-h6" :title="val.name">
                                                            <v-btn v-if="row.copy" icon small @click="copyContent(val.name)">
                                                                <v-list-item-icon class="mr-0">
                                                                    <v-icon size="small">mdi-content-copy</v-icon>
                                                                </v-list-item-icon>
                                                            </v-btn>
                                                            <a v-if="row.link"
                                                                :href="row.link(val.id)">
                                                                {{ val.name }}
                                                            </a>
                                                            <span v-else>{{ val.name }}</span>
                                                        </v-list-item-title>
                                                    </template>

                                                    <span>{{ val.name }}</span>
                                                </v-tooltip>
                                            </div>
                                        </v-col>

                                    </v-row>
                                </v-list-item-title>
                            </div>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- marketing -->
            <!--<v-expansion-panel class="mb-6">
                <v-expansion-panel-header>
                    <v-list-item>
                        <v-list-item-icon class="mr-2">
                            <v-icon>mdi-hub</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5">{{ $t('Marketing')}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-spacer></v-spacer>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-row>
                        <v-col :cols="12/marketingRows.length"
                            v-for="(colValue, cidx) in marketingRows"
                            :key="cidx">
                            <div v-for="(row, idx) in colValue"
                                :key="idx">
                                <div v-if="row.type === 'map'">{{  $t('Map') }}
                                    <div style="height:220px;width:220px;border:2px solid rgba(0,0,0,.5)">
                                        <v-map style="z-index:2;" ref="mappy" :zoom=13 :center="projectLocation">
                                            <v-tilelayer url="https://tile.openstreetmap.de/{z}/{x}/{y}.png"></v-tilelayer>
                                        </v-map>
                                    </div>
                                </div>
                                <v-list-item-title v-else :class="['subtitle-1', row.name === null ? 'mb-3' : 'mb-1']">
                                    <v-row>
                                        <v-col cols="4" align-self-center>
                                            <div>
                                                {{ row.name }}
                                            </div>
                                        </v-col>

                                        <v-col cols="8"  align-self-center>
                                            <div v-for="val, vidx in row.value.slice(0, 2)"
                                                :key="`row-val-${vidx}`">

                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{}">
                                                        <div v-if="row.type === 'text'">
                                                            <v-textarea solo flat readonly :value="val.name">
                                                            </v-textarea>
                                                        </div>
                                                        <div v-if="row.type === 'icon'">
                                                            <v-icon x-large>{{ val.name }}</v-icon>
                                                        </div>
                                                        <v-list-item-title v-else class="text-h6" :title="val.name">
                                                            <v-btn v-if="row.copy" icon small @click="copyContent(val.name)">
                                                                <v-list-item-icon class="mr-0">
                                                                    <v-icon size="small">mdi-content-copy</v-icon>
                                                                </v-list-item-icon>
                                                            </v-btn>
                                                            <a v-if="row.link"
                                                                :href="row.link(val.id)">
                                                                {{ val.name }}
                                                            </a>
                                                            <span v-else>{{ val.name }}</span>
                                                        </v-list-item-title>
                                                    </template>

                                                    <span>{{ val.name }}</span>
                                                </v-tooltip>
                                            </div>
                                        </v-col>

                                    </v-row>
                                </v-list-item-title>
                            </div>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>-->

            <!-- systems -->
            <v-expansion-panel class="mb-6">
                <v-expansion-panel-header>
                    <v-list-item>
                        <v-list-item-icon class="mr-2">
                            <v-icon>mdi-camera</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5">{{ $t('camera_overview') }} ({{ inputData.systems && inputData.systems.length }})</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-slide-group
                        v-model="systemSlide"
                        active-class="success"
                        show-arrows
                        >
                        <v-slide-item
                            v-for="system, sidx in inputData.systems"
                            :key="sidx"
                            class="mx-4"
                        >
                            <SystemTile
                                :id="`systemTile_${system.id}`"
                                :ref="`systemTile_${system.id}`"
                                :type="system.enabled"
                                :imageWidth="15"
                                :cameraNumber="parseInt(system.camNo)"
                                :computerName="system.computerData && system.computerData.computer.name"
                                :projectName="inputData.name"
                                :systemId="system.id"
                                :client="inputData.client ? inputData.client.name : ''"
                                :timezone="system.config && system.config.timezone"
                                :healthData.sync="system.health" />
                        </v-slide-item>
                    </v-slide-group>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- contacts -->
            <v-expansion-panel class="mb-6">
                <v-expansion-panel-header>
                    <v-list-item>
                        <v-list-item-icon class="mr-2">
                            <v-icon>mdi-account-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5">{{ $t('Contacts') }} ({{ inputData.contacts && inputData.contacts.length }})</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-slide-group
                        v-model="contactSlide"
                        active-class="success"
                        show-arrows
                        >
                        <v-slide-item
                            v-for="contact, sidx in inputData.contacts"
                            :key="sidx"
                            class="mx-4"
                        >
                            <ContactTile
                                :id="contact.id"
                                :enabled="contact.enabled"
                                :name="contact.name"
                                :surname="contact.surname"
                                :role="contact.role"
                                :gender="contact.gender"
                                :company="contact.company"
                                :phones="contact.numbers ? contact.numbers.split(',').map(number => ({ name: number.trim() })) : []"
                                :mails="contact.mails ? contact.mails.split(',').map(mail => ({ name: mail.trim() })) : []"
                                :projects="contact.projects ? contact.projects.map(project => ({ id: project.id, name: project.name })) : []"
                                :note="contact.note"
                                :editable="true"
                                :timezone="contact.timezone"
                                :languages="contact.languages ? contact.languages.split(',').map(language => language.trim()).join(', ') : ''"
                                :createdAt="contact.createdAt"
                                :updatedAt="contact.updatedAt"
                                @edit-item="editContact(contact)"/>
                        </v-slide-item>
                    </v-slide-group>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- notes -->
            <v-expansion-panel class="mb-6">
                <v-expansion-panel-header>
                    <v-list-item>
                        <v-list-item-icon class="mr-2">
                            <v-icon>mdi-message-bulleted</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5">{{ $t('notes') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <div class="d-flex justify-end">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon style="float:right;"
                                    @click.stop="showNewInputDialog('note', inputData.notes)"
                                    v-on="{ ...tooltip }">
                                    <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('New Note') }}</span>
                        </v-tooltip>
                    </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <div v-if="inputData.notes && inputData.notes.length > 0">
                        <v-row v-for="note, nidx in inputData.notes"
                            :key="nidx">
                            <v-col style="margin-top:.5em;position:relative;">
                                <Note :type="'note'" :content="note"></Note>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-else>
                        <v-row>
                            <v-card flat class="ma-2">
                                <v-card-text class="text-h6">
                                    {{ $t('no_notes_found') }}
                                </v-card-text>
                            </v-card>
                        </v-row>

                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>


<script>
// https://nominatim.openstreetmap.org/reverse?lat=53.551086&lon=9.993682&format=json
import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
} from 'vue2-leaflet'
import { mapState, mapActions } from 'vuex'
import InputMask from '@/components/InputMaskTabs.vue'
import SystemTile from '@/components/SystemTile.vue'
import ContactTile from '@/components/ContactTile.vue'
import { rest } from '../_helpers'

const Note = () => import(
    /* webpackChunkName: "inventory-details" */ '@/components/Note.vue'
)

const moment = require('moment-timezone')

export default {
    data() {
        return {
            id: parseInt(this.$route.params.id, 10),
            inputData: {},
            systems: {},
            sortedSystems: [],
            panel: [0, 1, 2, 3],
            contactSlide: null,
            systemSlide: null,
        }
    },

    components: {
        'v-map': LMap,
        'v-tilelayer': LTileLayer,
        SystemTile,
        ContactTile,
        Note,
    },

    computed: {
        projectRows() {
            return [
                [
                    { name: this.$t('projectno'), value: [{ name: this.inputData.id }] },
                    { name: this.$t('project_name'), value: [{ name: this.inputData.name }] },
                    { name: this.$t('Client'), value: [{ name: this.inputData.client && this.inputData.client.name }] },
                    { name: null, value: [] },
                    { name: this.$t('Start Date'), value: [{ name: this.inputData.startDate }] },
                    { name: this.$t('End Date'), value: [{ name: this.inputData.endDate }] },
                    { name: null, value: [] },
                    { name: this.$t('Country'), value: [{ name: this.inputData.country }] },
                    { name: this.$t('City'), value: [{ name: this.inputData.city }] },
                    { name: null, value: [] },
                    { name: this.$t('cameras'), value: [{ name: this.inputData.systems && this.inputData.systems.length }] },
                ],
                [
                    {
                        name: this.$t('status'),
                        type: 'icon',
                        value: [{ name: this.statusIcon(this.inputData.enabled) }],
                    },
                    { name: null, value: [] },
                    { name: this.$t('Location'), copy: true, value: [{ name: `${this.inputData.latitude}, ${this.inputData.longitude}` }] },
                    { name: null, value: [] },
                    { name: this.$t('project_path'), copy: true, value: [{ name: this.projectPath.value ? this.projectPath.value.replace(/[/]/g, '\\') : '' }] },
                    { name: null, value: [] },
                    { name: this.$t('Project Drive'), value: [{ name: this.inputData.projectDrive }] },
                ],
                [
                    { type: 'map' },
                ],
            ]
        },

        marketingRows() {
            return [
                [
                    { name: this.$t('projectno'), value: [{ name: this.inputData.id }] },
                    { name: this.$t('project_name'), value: [{ name: this.inputData.name }] },
                    { name: this.$t('Client'), value: [{ name: this.inputData.client && this.inputData.client.name }] },
                    { name: null, value: [] },
                    { name: this.$t('Start Date'), value: [{ name: this.inputData.startDate }] },
                    { name: this.$t('End Date'), value: [{ name: this.inputData.endDate }] },
                    { name: null, value: [] },
                    { name: this.$t('Country'), value: [{ name: this.inputData.country }] },
                    { name: this.$t('City'), value: [{ name: this.inputData.city }] },
                    { name: null, value: [] },
                    { name: this.$t('cameras'), value: [{ name: this.inputData.systems && this.inputData.systems.length }] },
                ],
                [
                    { name: this.$t('status'), type: 'icon', value: [{ name: this.inputData.enabled && (!this.inputData.endDate || moment(this.inputData.endDate).isAfter(moment())) ? 'mdi-folder' : 'mdi-archive' }] },
                    { name: null, value: [] },
                    { name: this.$t('Location'), copy: true, value: [{ name: this.projectLocation.join(', ') }] },
                    { name: null, value: [] },
                    { name: this.$t('project_path'), copy: true, value: [{ name: this.projectPath.value ? this.projectPath.value.replace(/[/]/g, '\\') : '' }] },
                    { name: null, value: [] },
                    { name: this.$t('Project Drive'), value: [{ name: this.inputData.projectDrive }] },
                ],
            ]
        },

        projectPath() {
            let projectPath = ''
            if (this.inputData) {
                if (this.inputData.id && this.inputData.name && this.inputData.name && this.inputData.city) {
                    projectPath += `${this.inputData.id} - ${this.inputData.client.name} - ${this.inputData.name} - ${this.inputData.city}`
                }
            }
            return { value: projectPath, hint: projectPath === '' ? this.$t('incomplete_path_data') : '' }
        },

        projectLocation() {
            /* let latitude = 0
            let longitude = 0

            if (this.inputData.systems) {
                let geoCount = 0
                for (const system of this.inputData.systems) {
                    if (system.config) {
                        latitude += system.config.latitude
                        longitude += system.config.longitude
                        geoCount += 1
                    }
                }

                latitude /= geoCount
                longitude /= geoCount
            } */

            return [this.inputData.latitude ? this.inputData.latitude : 0, this.inputData.longitude ? this.inputData.longitude : 0]
        },
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            setInputDialog: 'input/setInputDialog',
            showInputDialog: 'input/showInputDialog',
            setNew: 'input/setNew',
            resetNew: 'input/resetNew',
            showSnackbar: 'page/showSnackbar',
            setSnackbar: 'page/setSnackbar',
        }),

        statusIcon(status) {
            switch (status) {
            case 1:
                return 'mdi-folder'
            case 2:
                return 'mdi-clock'
            case 3:
                return 'mdi-pause-box'
            default:
                return 'mdi-archive'
            }
        },

        refresh() {
            this.systems = {}
            this.getData()
        },

        getData() {
            return rest.getRow('project', this.id)
                .then(item => {
                    this.inputData = item

                    let nextCamNo = 1
                    const { systems, notes } = item
                    if (systems) {
                        systems.forEach(system => {
                            if (system.camNo > nextCamNo) {
                                nextCamNo = system.camNo + 1
                            }

                            if (!(system.id in this.systems)) {
                                system.status = 0
                                system.name = system.id
                                this.systems[system.id] = system
                            }
                        })
                    }

                    if (notes) {
                        notes.forEach(note => {
                            note.type = 'note'
                        })
                    }

                    this.sortedSystems = Object.values(this.systems)

                    return nextCamNo
                })
                .finally(() => {
                    this.showSpinner(false)
                })
        },

        editProject() {
            const input = {
                id: this.inputData.id,
                visible: true,
                type: 'project',
                title: this.$t('Project'),
                data: ({ ...this.inputData }),
                modified: (rowId, rowData) => {
                    this.inputData = rowData

                    this.showInputDialog(false)
                },
            }

            this.setInputDialog(input)
        },

        editContact(item) {
            const input = {
                id: this.inputData.contacts.indexOf(item),
                visible: true,
                type: 'contact',
                title: this.$t('Contact'),
                data: ({ ...item }),
                modified: (rowId, rowData) => {
                    if (rowId > -1) {
                        this.$set(this.inputData.contacts, rowId, rowData)
                    }

                    this.showInputDialog(false)
                },
                preValidate: this.preValidate,
            }

            this.setInputDialog(input)
        },

        showNewInputDialog(type, updateList) {
            let referencefId
            let data = {}

            switch (type) {
            case 'note':
                referencefId = this.inputData.id
                data = { refId: referencefId, refType: 2 }
                break
            default:
                break
            }

            this.setInputDialog({
                type,
                confirm: false,
                visible: true,
                title: type.charAt(0).toUpperCase() + type.slice(1),
                data,
                modified: (idx, item) => {
                    if (Object.keys(item).length) {
                        updateList.push(item)
                    }
                    this.showInputDialog(false)
                },
            })
        },

        copyContent(copyData) {
            navigator.clipboard.writeText(copyData)

            this.setSnackbar({
                type: 'success',
                text: `${this.$t('Content copied!')}`,
            })
            this.showSnackbar(true)
        },
    },

    created() {
        this.showSpinner(true)

        this.getData()
            .then(camNo => {
                this.setNew({
                    type: 'system',
                    title: 'System',
                    data: { projectId: this.id, camNo },
                    modified: (idx, item) => {
                        this.refresh()
                        this.resetNew()
                    },
                })
            })
    },
}
</script>


<style lang="stylus" scoped>
    .tile-container
        margin 1em
        float left

    .single-page
        height 100%
        width 100%

    .single-page .single-page-input-two
        max-width 40em
        height 100%
        overflow-y auto

    @media screen and (max-width: 960px)
        .single-page .single-page-input-two
            width 100%
            flex-basis unset
            height unset
            max-width unset
</style>
